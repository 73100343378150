import React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

const ImageCarousel = ({ images }) => {
  return (
    <div className="vehicle-select__image">
      {images?.length > 1 ? (
        <Carousel showStatus={false} className="carousel" autoPlay={false} style={{ backgroundColor: 'transparent'}}>
          {images.slice(0, 4).map((image, index) => (
            <div key={index}>
              <img src={image.imageUrl} alt={image.fileName} />
            </div>
          ))}
        </Carousel>
      ) : (
        <img
          src={images[0].imageUrl}
          alt="placeholderVehicle"
          className="img-fluid"
        ></img>
      )}
    </div>
  )
}

export default ImageCarousel
